/* eslint-disable jsx-a11y/iframe-has-title */
const YaMap = () => {
  return (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        border: "3px solid #596DBB",
        borderRadius: "20px",
      }}
    >
      <iframe
        src="https://yandex.ru/map-widget/v1/?um=constructor%3Aef351a5b05f5bc2356db39a2cf1c34534730c22a6752827b07850db6b094adfb&amp;source=constructor"
        width="670"
        height="490"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default YaMap;
