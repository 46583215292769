import { Avatar } from "@mui/material";
import { ReporterData } from "../../types/report";
import "./styles.scss";

type Props = ReporterData;

const Reporter = ({ name, reporterName, photo }: Props) => {
  return (
    <div className="reporter">
      <div className="reporter-avatar">
        <Avatar
          sx={{ width: 54, height: 54 }}
          src={`${process.env.PUBLIC_URL}/assets/photos/${photo}`}
        />
      </div>
      <div className="reporter-info">
        <div className="reporter-info__name">{reporterName}</div>
        <div className="reporter-info__company">{name}</div>
      </div>
    </div>
  );
};

export default Reporter;
