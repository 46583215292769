import { ReportTagData } from "../../types/tags";
import "./styles.scss";

type Props = ReportTagData;

const ReportTag = ({ color, label }: Props) => {
  return (
    <div className="report-tag" style={{ background: color }}>
      {label}
    </div>
  );
};

export default ReportTag;
