import "./styles.scss";

interface Props {
  text: string;
}

const HeaderTag = ({ text }: Props) => {
  return <div className="tag">{text}</div>;
};

export default HeaderTag;
