/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/style-prop-object */
import "./styles.scss";
import YaMap from "./YaMap";

const Location = () => {
  return (
    <div id="location" className="location">
      <div className="location-wrapper">
        <div>
          <div className="location__info">
            <div className="location__info-title">Расположение</div>
            <div className="location__info-subtitle">КЗЦ «Миллениум»</div>
            <div className="location__info-address">
              Которосльная наб., 53, Ярославль
            </div>
          </div>
        </div>
        <div className="location__map">
          <YaMap />
        </div>
      </div>
    </div>
  );
};

export default Location;
