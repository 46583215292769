import { ReactComponent as YappiLogo } from "../../assets/yappi-logo.svg";
import { ReactComponent as Telegram } from "../../assets/telegram.svg";
import { ReactComponent as VK } from "../../assets/vk.svg";
import { ReactComponent as YouTube } from "../../assets/youtube.svg";
import "./styles.scss";

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <div className="footer-wrapper">
        <div className="footer__logo">
          <div className="footer__logo-logo">
            <YappiLogo />
          </div>
          <div className="footer__logo-subtitle">
            IT-конференция для нетворкинга и обмена опытом
          </div>
        </div>
        <div className="footer__contacts">
          <div className="footer__contacts-title">Наши каналы связи:</div>
          <a
            href="mailto:yappidaysmeetup@gmail.com"
            className="footer__contacts-email"
          >
            yappidaysmeetup@gmail.com
          </a>
          <div className="footer__contacts-socials">
            <a target="_blank" href="https://t.me/yappi_days">
              <Telegram />
            </a>
            <a target="_blank" href="https://vk.com/yappidays">
              <VK />
            </a>
            <a target="_blank" href="https://youtube.com/@yappidays2501">
              <YouTube />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
