import Reporter from "./Reporter";
import { Report } from "../../types/report";
import "./styles.scss";

const ReportCardEmpty = () => {
  const yappiBear: Report = {
    photo: "yappi_bear.png",
    reporterName: "Ярослав Мудрый",
    title: "Доклад в разработке",
    description: "",
    id: 10,
    tags: [],
    company: {
      name: "Yappi Days",
      position: "",
    },
  };

  return (
    <div className="report-card empty" >
      <div className="report-card-container">
        <div className="report-card__title">{yappiBear.title}</div>
        <div className="report-card__info">
          <div className="report-card__reporter">
            <Reporter
              {...yappiBear.company}
              reporterName={yappiBear.reporterName}
              photo={yappiBear.photo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportCardEmpty;
