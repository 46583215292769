import { ReactComponent as RaftLogo } from "../../assets/raft-logo.svg";
import { ReactComponent as PvsStudioLogo } from "../../assets/pvs-studio-logo.svg";
import "./styles.scss";

const Partners = () => {
  return (
    <div className="partners">
      <div className="partners-wrapper">
        <div className="partners__container">
          <span className="partners__title">Партнеры</span>
          <div className="partners__list">
            <div className="partners__logo">
              <a target="_blank" href="https://raftds.ru/" className="partners__link">
                <RaftLogo className="partners__icon"/>
              </a>
            </div>
            <div className="partners__logo">
              <a target="_blank" href="https://pvs-studio.ru/ru/home/" className="partners__link--pvs">
                <PvsStudioLogo className="partners__icon"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
