import { ReportTagData } from "../types/tags";

export enum Tags {
  Algorithms,
  StaticCodeAnalysis,
  CPlusPlus,
  NeuralNetworks,
  Security,
  Architecture,
  Performance,
  CI,
  AWS,
  Tools,
  SoftSkills,
  DataScience,
  Framework,
  AIAgents,
  AI,
  Management,
  Break,
  Trends,
  Mobile,
  Startup,
  LLM
}

export const tagsMap = new Map<Tags, ReportTagData>([
  [Tags.Algorithms, { label: "Алгоритмы", color: "#09E3D95C" }],
  [Tags.Tools, { label: "Инструменты", color: "#09E3D95C" }],
  [Tags.Architecture, { label: "Архитектура", color: "#AFC4FF" }],
  [Tags.AWS, { label: "AWS", color: "#EADAFF" }],
  [Tags.SoftSkills, { label: "Soft Skills", color: "#EADAFF" }],
  [Tags.CI, { label: "Continuous Integration", color: "#FFD6A5" }],
  [Tags.CPlusPlus, { label: "C++", color: "#DBFFC0" }],
  [Tags.NeuralNetworks, { label: "Нейронные сети", color: "#FDFFB6" }],
  [Tags.Performance, { label: "Производительность", color: "#FFADAD" }],
  [Tags.DataScience, { label: "Data Science", color: "#FFADAD" }],
  [Tags.Security, { label: "Безопасность", color: "#C5E5F3" }],
  [Tags.AIAgents, { label: "AI-агенты", color: "#BDECB6" }],
  [Tags.Framework, { label: "Фреймворк", color: "#EFAF8C" }],
  [Tags.StaticCodeAnalysis, { label: "Статический анализ кода", color: "#B8F4D4" }],
  [Tags.AI, { label: "AI", color: "#7AC5CD" }],
  [Tags.Management, { label: "Управление", color: "#BBE39A" }],
  [Tags.Break, { label: "Break", color: "#EADAFF" }],
  [Tags.LLM, { label: "LLM", color: "#DBFFC0" }],
  [Tags.Trends, { label: "Тренды", color: "#FFC0CB" }],
  [Tags.Mobile, { label: "Mobile", color: "#D3D3D3" }],
  [Tags.Startup, { label: "Стартап", color: "#FFDAB9" }]
]);