import { Avatar } from "@mui/material";
import { Organizer } from "../../types/org";
import "./styles.scss";

type Props = Organizer;

const OrgCard = ({ name, photo, position }: Props) => {
  return (
    <div className="organizer">
      <div>
        <Avatar
          sx={{ width: 130, height: 130 }}
          src={`${process.env.PUBLIC_URL}/assets/photos/${photo}`}
        />
      </div>
      <div className="organizer__info">
        <div className="organizer__info-name">{name}</div>
        <div className="organizer__info-position">{position}</div>
      </div>
    </div>
  );
};

export default OrgCard;
