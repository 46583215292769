import { Data } from "../components/Schedule/Schedule";
import { Tags } from "../constants/tags";
import { Report } from "../types/report";

export const mockedData: Data = [
  {
    time: "10:00",
    label: "Регистрация участников",
  },
  {
    time: "10:45",
    label: "Открытие конференции",
  },
  {
    time: "11:00",
    label: "Открытие конференции",
    reports: [1, 2, 3],
  },
  {
    time: "11:40",
    label: "Перерыв",
  },
];

export const reportsData: Report[] = [
  {
    id: 1,
    reporterName: "Сергей Марков",
    photo: "Сергей_Марков.jpg",
    title:
      "Искусственный интеллект в эпоху больших языковых моделей: вызовы, направления развития и области применения",
    description:
      "Итогом десятилетия революции глубокого обучения стал быстрый прогресс технологий генеративного искусственного интеллекта и больших языковых моделей. Ещё 5 лет назад трудно было представить себе, что машины будут соревноваться с людьми в написании статей, программного кода или в создании картин.\n\nИз доклада Сергея Маркова вы узнаете о наиболее продвинутых проектах в области ИИ в последние годы, об исследованиях в этой области, об открытых вопросах, стоящих сегодня перед разработчиками систем ИИ, о перспективах развития таких систем и о том, как современные инструменты ИИ применяются в различных направлениях человеческой деятельности.",
    company: {
      name: "ПАО Сбербанк",
      position:
        "Управляющий директор — начальник Управления экспериментальных систем машинного обучения Дивизион общих сервисов «Салют» ПАО Сбербанк",
    },
    tags: [Tags.LLM],
  },
  {
    id: 2,
    reporterName: "Дмитрий Рыбалко",
    photo: "Дмитрий_Рыбалко.jpg",
    title:
      "Технические сложности, с которыми сталкиваются компании при внедрении LLM",
    description:
      "Языковые модели бесспорно являются одной из самых хайповых технологий на текущий момент. С ними интересно пообщаться, они помогают писать код, но когда дело доходит до внедрения в бизнес-процессы, возникает ряд нюансов и сложностей: как бороться с галлюцинациями, как обучить модель на своих данных, как обеспечить безопасность данных, как работать с большими нагрузками и т.д. Опираясь на опыт работы с клиентами вокруг YandexGPT, мы обсудим сложности и возможные пути их решения.",
    company: {
      name: "Yandex Cloud",
      position: "Продуктовый архитектор ML-сервисов, Yandex Cloud",
    },
    tags: [Tags.Architecture, Tags.Tools],
  },
  {
    id: 3,
    reporterName: "Василий Ванчук",
    photo: "Василий_Ванчук.jpg",
    title: "The Good, the Bad and NPX",
    description:
      "Доклад о том, как простая задача на проекте привела к улучшению сторонних пакетов на GitHub, но не помогла с основным проектом. В результате это привело к мини-исследованию, которым хочу поделиться с коллегами, чтобы предупредить о возможных рисках. Добавим к этому немного экспериментов и статистики и обсудим, как это может повлиять на всех нас.",
    company: {
      name: "Т-Банк",
      position: "Ведущий эксперт в Т-Банке",
    },
    tags: [Tags.Tools, Tags.Security],
  },
  {
    id: 4,
    reporterName: "Анастасия Калашникова",
    photo: "Анастасия_Калашникова.jpg",
    title:
      "Концентрация и фокус: как погружаться в сложные задачи и сохранять продуктивность",
    description:
      "В докладе расскажу, что нас отвлекает от рабочих задач, даже если они интересные. Почему это происходит и чем это помогает нам в работе. Как мы можем использовать тело, мысли и пространство для улучшения внимания.И использовать отвлекающие нас факторы в свою пользу. Расскажу, как кричащий ребенок может повысить продуктивность. А абсолютная тишина наоборот - понизить ее.",
    company: {
      name: "PSYvIT",
      position: "Кризисный психолог, основатель проекта PSYvIT",
    },
    tags: [Tags.SoftSkills],
  },
  {
    id: 5,
    reporterName: "Ольга Кравченко",
    photo: "Ольга_Кравченко.jpg",
    title: "Collection. Темная сторона Data Science",
    description: `Что первое приходит на ум, когда вы слышите фразу «банковское взыскание»? Незамолкающий телефон? Стук в дверь? Примитивные методы, основанные на примитивной логике? \n\nМы сломаем это клише и расскажем вам, как распределенная команда дата-сайентистов, дата-инженеров и автоматизаторов за несколько лет изменила подход к системе Collection, повернув ее вектор в сторону заботы о клиентах и сотрудниках.`,
    company: {
      name: "Газпромбанк.Тех",
      position:
        "Data Scientist блока Collection Газпромбанка. Создает эффективные ML-решения для розничного взыскания, опираясь на принципы заботы о клиентах и сотрудниках.",
    },
    tags: [Tags.DataScience],
  },
  {
    id: 6,
    reporterName: "Александр Константинов",
    photo: "Александр_Константинов.jpg",
    title:
      "Искусственный Интеллект на Перекрестке: Маршрутизация и Классификация в AI Приложениях",
    description:
      "В докладе будут рассмотрены вопросы интеграции различных источников данных и действий для AI-агентов. Обсуждение охватит три техники и их применимость в решении бизнес-задач, учитывая потребности приложения и доступность данных на начальной стадии разработки.\n\nПодход ориентирован на AI-агентов, которым необходимо получать данные из разнообразных источников и маршрутизировать цепочки взаимодействий.",
    company: {
      name: "Raft",
      position: "CTO в направлении AI компании Raft",
    },
    tags: [Tags.AIAgents, Tags.Framework],
  },
  {
    id: 7,
    reporterName: "Денис Мазилов",
    photo: "Денис_Мазилов.jpg",
    title: "Архитектурная функция на вашем проекте",
    description:
      'В разных компаниях и командах зачастую по-разному видят роль архитектора. При этом нередко процесс разработки не оптимален, архитектурные знания и решения теряются, на коммуникации уходит очень много времени. \n\nВ рамках доклада раскрою свой взгляд на функции архитектора и его роль в общем процессе. Обсудим архитектурные артефакты, которые помогут всей команде быть на едином уровне понимания. А также поговорим о том, как организовать конвейер проработки задачи от бизнес-требования до выхода в продуктив, не теряя "по дороге" архитектурно-значимые детали.',
    company: {
      name: "ДОМ.РФ Технологии",
      position: "Solution Architect",
    },
    tags: [Tags.Architecture],
  },
  {
    id: 8,
    reporterName: "Алексей Рагозин",
    photo: "Алексей_Рагозин.jpg",
    title: "Мониторинг — матрица рисков эксплуатации",
    description:
      "Хороший мониторинг зачастую рождается как реакция на инциденты и сбои в ходе эксплуатации. Но что делать, если мы не хотим дожидаться сбоев, а стремимся построить мониторинг заранее? Можно действовать по принципу “мониторим всё, что можно”, но и у этого подхода есть проблемы — в первую очередь, это бюджет. В рамках доклада попробуем ответить на непростые вопросы, которые возникают при работе над мониторингом, в частности:\n•	Почему эксплуатационники и разработчики по-разному смотрят на мониторинг?\n•	Как выявлять дыры в мониторинге и расставлять приоритеты?\n•	Чем хорошая метрика отличается от плохой?",
    company: {
      name: "Независимый эксперт",
      position:
        "Алексей специализируется на разработке высоконагруженных распределённых систем на платформе Java. За свою карьеру в индустрии он собрал опыт разработки решений в таких отраслях, как телеком, E-commerce, здравоохранение и финтех.",
    },
    tags: [Tags.Tools],
  },
  {
    id: 9,
    reporterName: "Инна Пристягина",
    photo: "Инна_Пристягина.jpg",
    title: "Матрица компетенций в небольшой компании: 5 стадий принятия",
    description:
      "Матрицы компетенций – нужны ли они в небольшой компании? Как понять, когда время пришло? Какие стадии компания проходит перед принятием необходимости матриц компетенций? От досок в Notion до отдельного инструмента управления компетенциями. Расскажу весь путь на примере внедрения матриц компетенций.",
    company: {
      name: "PVS-Studio",
      position: "Руководитель по развитию персонала в PVS-Studio",
    },
    tags: [Tags.Tools, Tags.Management],
  },
  {
    id: 10,
    reporterName: "Эрик Бурыгин",
    photo: "Эрик_Бурыгин.jpg",
    title: "Сила нетворкинга, или Нетворкинг как стиль жизни",
    description:
      "Нетворкинг является мощнейшим инструментом в жизни как руководителя любого уровня, так и обычного инженера. Так почему же им часто пренебрегают? В докладе разберемся в этом.\nНа личном опыте расскажу, как нетворкинг помогает мне реализовать различные проекты. Как подступиться к нетворкингу, где им заниматься, какие есть хаки.",
    company: {
      name: "Яндекс",
      position:
        "Руководит командой тестирования мобильного приложения Яндекс Недвижимость / Аренда, также является техлидом в Яндекс Практикуме",
    },
    tags: [Tags.SoftSkills],
  },
  {
    id: 11,
    reporterName: "Илья Нестеров",
    photo: "Илья_Нестеров.jpg",
    title: "Мастер-класс по кикеру",
    description:
      "Немного расскажу про профессиональный кикер — какие бывают столы, соревнования, где в мире играют в кикер. Поговорим о том, какие элементы игры есть в спортивном кикере, покажу некоторые из них.",
    company: {
      name: "Федерация спортивного кикера",
      position:
        "Вице-чемпион России сезонов 2022 и 2023, топ-5 игрок российского рейтинга, руководитель ярославского отделения Федерации спортивного кикера",
    },
    tags: [Tags.Break],
  },
  {
    id: 12,
    reporterName: "Александр Сычев",
    photo: "Александр_Сычев.jpg",
    title: "Мобильная разработка в РФ: держим руку на пульсе",
    description:
      "В докладе обсудим, что происходит на рынке мобильной разработке в 2024-м году: как устроен найм, каких специалистов ищет рынок, какие приложения и платформы востребованы, а какие остаются только в статьях.",
    company: {
      name: "Яндекс Go | Эксперт в KTS",
      position:
        "13 лет создаю мобильные приложения. 9 лет руковожу и провожу трансформации больших команд разработки (Яндекс, Т-Банк, Сбер, RAMBLER&Co)",
    },
    tags: [Tags.Trends, Tags.Mobile],
  },
  {
    id: 13,
    reporterName: "Александр Горный",
    photo: "Александр_Горный.jpg",
    title: "Название в разработке",
    description:
      `Заместитель генерального директора в "Ситимобил", агрегаторе такси, который вырос за год в 15 раз. Бывший директор по стратегии и анализу в Mail Group.\n\nЧерез его экспертизу прошли крупнейшие инвестиционные сделки Мейла, например, Delivery Club и Ситимобил.\nДесятки других возможностей его фильтр не преодолели.`,
    company: {
      name: "United Investors",
      position:
        "Бывший директор по стратегии в Mail.Ru Group, автор блога #стартапдня, управляющий партнер United Investors",
    },
    tags: [Tags.Startup, Tags.Management],
  },
  {
    id: 14,
    reporterName: "Александр Дружков",
    photo: "Александ_Дружков.jpg",
    title: "Приключение на 10 минут длинною в трое суток",
    description:
      `Расскажем о том, как один раз в квартал часть из нас делает все, чтобы Иви упал, а другая часть - чтобы этого не произошло.\nУглубимся в одну из таких конкретных историй, когда во время учений по отключению ДЦ мы получили неконсистентность данных в платежных сервисах.\nРасскажем, что мы делали в процессе работ для восстановления работоспособности, как отрабатывали проблемы пользователей.\nА также о культуре постмортемов в нашей компании.`,
    company: {
      name: "Иви",
      position:
        "Заместитель CTO Иви",
    },
    tags: [],
  },
];
