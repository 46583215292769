import { Button } from "@mui/material";
import "./styles.scss";

const BuyTicket = () => {
  const getCurrencyFormat = (number: number) => {
    return new Intl.NumberFormat("ru-RU", {
      style: "currency",
      currency: "RUB",
      maximumFractionDigits: 0,
    }).format(number);
  };

  return (
    <div id="ticket" className="ticket">
      <div className="ticket-wrapper">
        <div className="ticket__info">
          <div className="ticket__info-title">Что входит в билет?</div>
          <ul className="ticket__info-list">
            <li>3 трека докладов</li>
            <li>Обед</li>
            <li>Кофе-брейки</li>
            <li>Ранний доступ к видеозаписям</li>
            <li>Нетворкинг</li>
            <li>Afterparty</li>
          </ul>
        </div>
        <div className="ticket__types">
          <div className="ticket__types-wrapper">
            <div className="ticket__types-info">
              <div className="ticket__types-info__title">Стандартный</div>
            </div>
            <div className="ticket__types-button">
              {/* <a
                href=""
                id="timepad_twf_register_2994775"
                data-timepad-customized="29906"
                data-timepad-widget-v2="event_register"
              ></a> */}
              <Button id="timepad_twf_register_2994775" className="buy-button">
                Купить билет
              </Button>
              <div className="ticket__types-button__prices">
                <span className="price">{getCurrencyFormat(5000)}</span>
              </div>
            </div>
          </div>
          <div className="ticket__types-wrapper">
            <div className="ticket__types-info">
              <div className="ticket__types-info__title">Студенческий</div>
              <div className="ticket__types-info__subtitle">
                При наличии действующего студенческого билета.
              </div>
            </div>
            <div className="ticket__types-button">
              <Button id="timepad_twf_register_2994775" className="buy-button">
                Купить билет
              </Button>
              <div className="ticket__types-button__prices">
                <span className="price">{getCurrencyFormat(3000)}</span>
              </div>
            </div>
          </div>
          <div className="ticket__types-wrapper">
            <div className="ticket__types-info">
              <div className="ticket__types-info__title">Для организаций</div>
              <div className="ticket__types-info__subtitle">
                При необходимости оформления сопровождающей документации
              </div>
            </div>
            <div className="ticket__types-button">
              <a href="mailto:yappidaysmeetup@gmail.com">
                <Button className="buy-button">Запросить цену</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyTicket;
