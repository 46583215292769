import { Organizer } from "../types/org";

export const orgs: Organizer[] = [
  {
    name: "Илья Козырев",
    photo: "Илья_Козырев.jpg",
    position: "Глава Программного комитета",
  },
  {
    name: "Евгений Кокуйкин",
    photo: "Евгений_Кокуйкин.jpg",
    position: "Член Программного комитета",
  },
  {
    name: "Олег Елифантьев",
    photo: "Олег_Елифантьев.jpg",
    position: "Член Программного комитета",
  },
  {
    name: "Артем Караваев",
    photo: "Артем_Караваев.jpeg",
    position: "Член Программного комитета",
  },
  {
    name: "Ирина Николаева",
    photo: "Ира_Николаева.jpg",
    position: "Член Программного комитета",
  },
  {
    name: "Александра Домашина",
    photo: "Александра_Домашина.jpg",
    position: "Организатор",
  },
  {
    name: "Вадим Матюненко",
    photo: "Вадим_Матюненко.jpg",
    position: "Координатор",
  },
];
