import ReportTag from "../Tags/ReportTag";
import { Avatar, Button } from "@mui/material";
import { ReactComponent as LeftArrowIcon } from "../../assets/left-arrow.svg";
import { Report } from "../../types/report";
import { tagsMap } from "../../constants/tags";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

interface Props {
  report: Report;
}

const Details = ({ report }: Props) => {
  const navigate = useNavigate();

  const onBackButtonClick = () => {
    navigate("/");
  };

  return (
    <div className="details">
      <div className="details-wrapper">
        <div className="details__reporter">
          <Avatar
            sx={{ width: 170, height: 170 }}
            src={`${process.env.PUBLIC_URL}/assets/photos/${report.photo}`}
          />
          <div>
            <div className="details__reporter-name">{report.reporterName}</div>
            <div className="details__reporter-position">{report.company.position}</div>
          </div>
        </div>
        <div className="details__info">
          {/* <div></div> */}
          <div className="details__info-title">{report.title}</div>
          <div className="details__info-description">{report.description}</div>
          {/* <div>Для кого</div>
          <div></div> */}
          <div className="details__info-bottom">
            <div className="details__info-tags">
              {report.tags.map((tag, index) => {
                const tagData = tagsMap.get(tag);

                return <ReportTag key={`details-tag-${index}`} {...tagData} />;
              })}
            </div>
            <div className="details__info-back">
              <Button onClick={onBackButtonClick}>
                <LeftArrowIcon />
                На главную
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
