import { orgs } from "../../data/orgs";
import OrgCard from "../OrgCard/OrgCard";
import "./styles.scss";

const Organizers = () => {
  return (
    <div id="organizers" className="organizers">
      <div className="organizers-wrapper">
        <span className="organizers__title">Организаторы</span>

        <div className="organizers__list">
          {orgs.map((org, index) => (
            <OrgCard key={`organizer-${index}`} {...org} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Organizers;
